import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import './cart-product-instance-popup.scss';
import {Popup, Form} from 'henrybuilt-react-library';
import IconGrid from 'components/icon-grid/icon-grid';
import PriceDisplay from 'components/price-display/price-display';
import {connect} from '@symbolic/redux';
import {updateCartProductInstance, removeCartProductInstance} from 'reducers/cart/cart-reducer';

class CartProductInstancePopup extends Component {
  static instanceKeys = ['productId', 'quantity', 'dimensions', 'materials', 'pulls', 'pullMaterials', 'productDetails'];

  state = {
    uuid: 11111111-1111-4111-2111-111111111111,
    productId: 1000000,
    dimensions: {},
    materials: {},
    pulls: {},
    pullMaterials: {},
    productDetails: {},
    quantity: 1
  }

  componentDidMount() {
    this.setState({...this.defaultState, ..._.cloneDeep(this.props.productInstance)});
  }

  get defaultState () {
    var {constraints, additionalConstraints, materialAssociations, pulls: pullChoices, productDetails: productDetailChoices} = this.props.product;

    var dimensions = {}, materials = {}, pulls = {}, pullMaterials = {}, productDetails = {};

    if (!_.isEmpty(additionalConstraints.standardSizes)) {
      _.forEach(['width', 'height', 'depth'], dimension => {
        var constraint = additionalConstraints.standardSizes[0][dimension];

        dimensions[dimension] = !_.isEmpty(constraint.fixed) ? constraint.fixed[0] : constraint.min;
      });

      dimensions.standardSize = additionalConstraints.standardSizes[0].key;
    }
    else {
      _.forEach(_.extend(constraints, _.get(additionalConstraints, 'additionalDimensions')), (constraint, key) => {
         if (constraint.fixed[0] || constraint.min) dimensions[key] = !_.isEmpty(constraint.fixed) ? constraint.fixed[0] : constraint.min;
      });
    }

    _.forEach(materialAssociations, materialAssociation => {
      materials[materialAssociation.key] = _.pick(materialAssociation.materials[0], ['title', 'id']);
    });

    if (!_.isEmpty(pullChoices)) {
      pulls['pull'] = _.pick(pullChoices[0], ['title', 'id', 'materialClass', 'isMortised']);

      if (!_.isEmpty(pullChoices[0].materialClass.materials[0])) {
        pullMaterials['pullMaterial'] = _.pick(pullChoices[0].materialClass.materials[0], ['title', 'id']);
      }
      else {
        pullMaterials = null;
      }
    }
    else {
      pulls = null;
      pullMaterials = null;
    }

    _.forEach(productDetailChoices, productDetailChoice => {
      productDetails[productDetailChoice.id] = _.pick(productDetailChoice.productDetailOptions[0], ['title', 'id']);
    });

    return {dimensions, materials, pulls, pullMaterials, productDetails, productId: this.props.product.id};
  }

  componentDidUpdate() {
    var pull = _.get(this.state.pulls, 'pull');

    if (pull) {
      var selectedPullIsCompatible = !(this.frontIsAluminum && pull.isMortised) || this.usingIntegratedEdgePull;

      if (!selectedPullIsCompatible) pull = _.find(this.props.product.pulls, {isMortised: 0});

      var selectedPullMaterialIsCompatible = _.some(_.get(pull, 'materialClass.materials'), material => (
        (material.id === this.state.pullMaterials.pullMaterial.id && !(this.frontIsAluminum && this.pullMaterialIsBrass))
      ));

      if (_.get(pull, 'materialClass.materials.length') && !_.isEmpty(this.state.pulls) && !selectedPullMaterialIsCompatible) {
        this.setState({
          pulls: {pull},
          pullMaterials: {pullMaterial: _.pick(pull.materialClass.materials[0], ['title', 'id'])}
        });
      }
      else if (!selectedPullIsCompatible) {
        this.setState({pulls: {pull}});
      }
    }
  }

  addToCart = async ({path}) => {
    clearTimeout(this.justAddedToCartTimer);

    var uuid = lib.string.uuid();
    var justAddedToCart = false;

    var pickedKeys = _.filter(CartProductInstancePopup.instanceKeys, detailType => this.state[detailType]);

    await this.props.updateCartProductInstance({productInstance: {
      ..._.pick(this.state, pickedKeys),
      uuid
    }});

    if (path) {
      this.props.history.push(path);
    }
    else {
      justAddedToCart = true;

      this.justAddedToCartTimer = setTimeout(() => this.setState({justAddedToCart: false}), 3000);
    }

    this.setState({uuid, justAddedToCart});
  }

  update = async () => {
    this.props.onClose();

    var pickedKeys = _.filter(CartProductInstancePopup.instanceKeys, detailType => this.state[detailType]);

    await this.props.updateCartProductInstance({productInstance: {..._.pick(this.state, [...pickedKeys, 'uuid'])}});
  }

  removeFromCart = async () => {
    this.props.onClose();

    await this.props.removeCartProductInstance({productInstance: {...this.state}});
  }

  handleChange = ({detailKey, key, value}) => {
    if (_.includes(['materials', 'pulls', 'pullMaterials', 'productDetails'], detailKey)) {
      value = _.pick(value, ['id', 'title', 'materialClass', 'isMortised']);
    }

    if (key === 'standardSize') {
      var standardSize = _.find(this.props.product.additionalConstraints.standardSizes, {key: value});
      var dimensions = {standardSize: value};

      _.forEach(['width', 'height', 'depth'], dimension => {
        var constraint = standardSize[dimension];

        dimensions[dimension] = !_.isEmpty(constraint.fixed) ? constraint.fixed[0] : constraint.min;
      });

      this.setState({dimensions});
    }
    else if (detailKey === 'quantity') {
      this.setState({quantity: value});
    }
    else {
      this.setState(oldState => ({[detailKey]: {...oldState[detailKey], [key]: value}}));
    }
  }

  get frontIsAluminum() {
    return _.includes(_.lowerCase(_.get(this.state.materials, 'front.title')), 'aluminum');
  }

  get pullMaterialIsBrass() {
    return _.includes(_.lowerCase(_.get(this.state.pullMaterials, 'pullMaterial.title')), 'brass');
  }

  get usingIntegratedEdgePull() {
    return _.get(this.state.pulls, 'pull.id') === 15;
  }

  render() {
    var {handleChange, frontIsAluminum} = this;
    var {productInstance, onClose, product, session} = this.props;

    var {
      dimensions,
      materials: displayedMaterials,
      pulls: displayedPulls,
      quantity: displayedQuantity,
      pullMaterials: displayedPullMaterials,
      productDetails: displayedProductDetails,
      justAddedToCart
    } = this.state;

    var {title, media, materialAssociations, pulls, productDetails} = product;
    var {url: primaryImageUrl} = _.find(media, {type: _.some(media, {type: 'vimage', subjectType: 'standard'}) ? 'vimage' : 'image', subjectType: 'standard'});

    var pullMaterials = _.get(displayedPulls, 'pull.materialClass.materials', {});

    if (frontIsAluminum && !this.usingIntegratedEdgePull) {
      pulls = _.filter(pulls, {isMortised: 0});

      pullMaterials = _.filter(pullMaterials, ({title}) => !_.includes(_.lowerCase(title), 'brass'));
    }

    //HINT allow higher order quantity for signal stools
    var quantityLimit = _.includes([632, 633, 634], product.id) ? 7 : 5;

    var productInstancePropsData = [
      {
        key: 'dimensions',
        title: 'Dimensions',
        shouldRender: true,
        renderBody: () => (
          <DimensionsSelector className='cart-dimensions' {...{product, handleChange, dimensions}}/>
        )
      },
      {
        key: 'materials',
        title: 'Materials',
        shouldRender: true,
        renderBody: () => (
          <IconGrid
            categories={materialAssociations} detailType='material' optionKey='materials' activeOptions={displayedMaterials}
            onSelect={({selectedOption, selectedCategory}) => handleChange({detailKey: 'materials', key: selectedCategory.key, value: selectedOption})}
          />
        )
      },
      {
        key: 'pulls',
        title: 'Pulls',
        shouldRender: !_.isEmpty(pulls),
        renderBody: () => (
          <IconGrid
            categories={[{pulls, key: 'pull'}]} detailType='pull' optionKey='pulls' activeOptions={displayedPulls}
            onSelect={({selectedOption, selectedCategory}) => handleChange({detailKey: 'pulls', key: selectedCategory.key, value: selectedOption})}
          />
        )
      },
      {
        key: 'pullMaterials',
        title: 'Pull Materials',
        shouldRender: !_.isEmpty(pullMaterials),
        renderBody: () => (
          <IconGrid
            categories={[{pullMaterials: pullMaterials, key: 'pullMaterial'}]} detailType='pullMaterial' optionKey='pullMaterials' activeOptions={displayedPullMaterials}
            onSelect={({selectedOption, selectedCategory}) => handleChange({detailKey: 'pullMaterials', key: selectedCategory.key, value: selectedOption})}
          />
        )
      },
      {
        key: 'productDetailOptions',
        title: 'Other Options',
        shouldRender: !_.isEmpty(productDetails),
        renderBody: () => (
          <IconGrid
            categories={productDetails} optionKey='productDetailOptions' activeOptions={displayedProductDetails}
            onSelect={({selectedOption, selectedCategory}) => handleChange({detailKey: 'productDetails', key: selectedCategory.id, value: selectedOption})}
          />
        )
      },
      {
        key: 'quantity',
        title: 'Quantity',
        shouldRender: true,
        renderBody: () => (
          <Form.DropdownInput
            className='cart-quantity' value={displayedQuantity.toString() || '1'}
            options={_.map(_.range(1, quantityLimit), num => ({title: num.toString(), value: num.toString()}))}
            on={{change: ({value}) => handleChange({value: Number(value), detailKey: 'quantity'})}}
          />
        )
      }
    ];

    return (
      <Popup onClose={onClose} position='right'>
        <div className='product-meta'>
          <div className='product-thumbnail' style={{backgroundImage: `url(${primaryImageUrl})`}}></div>
          <div className='product-title'>{title}</div>
        </div>
        <Form>
          {_.map(productInstancePropsData, ({key, title, shouldRender, renderBody}) => (
            shouldRender && <div key={key} className={`product-instance-prop ${key}-prop`}>
              <div className='prop-header'>
                <div className='prop-title'>{title}</div>
              </div>
              <div className='prop-body'>{renderBody()}</div>
            </div>
          ))}
        </Form>
        <div className='popup-buttons'>
          {this.state.productId !== 1000000 && <PriceDisplay {...{productInstance: this.state, product, user: _.get(session, 'user')}}/>}
          {productInstance ? (
            <div className='cart-buttons'>
              <div className='button' onClick={this.update}>finished updating</div>
              <div className='button' onClick={this.removeFromCart}>remove</div>
            </div>
          ) : (
            <div className='cart-buttons'>
              <div className='button' onClick={this.addToCart}>{justAddedToCart ? 'added to cart!' : 'add to cart'}</div>
              <div className='button' onClick={() => this.addToCart({path: '/cart'})}>add & go to cart</div>
            </div>
          )}
        </div>
      </Popup>
    );
  }
}

export default connect({mapDispatch: {updateCartProductInstance, removeCartProductInstance}})(CartProductInstancePopup);

var DimensionsSelector = ({product, handleChange, dimensions={}}) => {
  var dimensionsData = _.flatMap(lib.dimensions.dimensionDataSetsFor({product, productInstance: {dimensions}}), 'dimensionsData');
  var standardSizes = _.get(product.additionalConstraints, 'standardSizes');

  var optionsFor = ({values}) => _.map(values, value => ({value: value.toString(), title: `${value}"`}));

  return (
    <div className='dimensions-selector'>
      {!!standardSizes && (
        <div className='linked-dimensions-selector'>
          <Form.RadioInput
          value={dimensionsData[0].value}
          on={{change: ({value}) => {
            handleChange({value, key: 'standardSize', detailKey: 'dimensions'});
          }}}
          options={_.map(standardSizes, ({key, title}) => ({value: key, title}))}
          />
        </div>
      )}
      {_.map(_.reject(dimensionsData, {key: 'standardSize'}), ({title, key, value, values}) => {
        return (
          value && <div className='dimension-container' key={title}>
            <div className='dimension-title'>{title}</div>
            {_.isEmpty(values) || values.length === 1 ? (
                <div className='dimension-fixed'>{value}"</div>
              ) : (
                <>
                  <Form.DropdownInput
                    className='dimension-dropdown'
                    {...{options: optionsFor({values}), value: value.toString()}}
                    on={{change: ({value}) => handleChange({value: Number(value), key, detailKey: 'dimensions'})}}
                  />
                </>
              )
            }
          </div>
        );
      })}
    </div>
  );
};
